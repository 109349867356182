import type {
  AGuardOnFailureProps,
  AGuardState,
  AGuardStep,
  AGuardStepCheckerProps,
  AGuardStepCheckerReturns,
  AGuardStepGroup,
} from "../_model";
import { general } from "@qundus.tc/agnostic.helpers";

export async function checkGuardSteps<T extends AGuardStepGroup>(props: {
  steps: T;
  events: {
    next?: (step: AGuardStep) => Omit<AGuardStepCheckerProps, "state">;
    success?: () => void;
    failure?: (props: Pick<AGuardOnFailureProps, "error" | "step" | "stepEquals">, key: any) => void;
  };
}) {
  const { events, steps } = props;
  // chek the important events
  if (!events) {
    console.error("Guarded: you need to set the ready event handler for guard!");
    return;
  }
  if (!steps) {
    events?.success?.();
    return;
  }
  let failed_step: AGuardStep = undefined;
  let failed_step_key: any = undefined;
  let failed_error: AGuardStepCheckerReturns = undefined;
  for (const key in steps) {
    const step = steps[key];
    failed_step = undefined;
    failed_error = undefined;
    if (!step?.runOn || step?.runOn?.length <= 0) {
      failed_error = {
        error: "checkGuardSteps: unknown guard run on sequence! in " + step,
      };
      throw new Error(
        "checkGuardSteps: step missing runOn property and/or unknown guard run on sequence!, please specify the run on option " +
          step
      );
    } else if (!(step.runOn instanceof Set)) {
      let run_on_array = !step?.runOn
        ? undefined
        : typeof step?.runOn === "string"
        ? [step?.runOn]
        : step?.runOn?.length <= 0
        ? undefined
        : step?.runOn;
      steps[key].runOn = new Set(run_on_array) as any;
      // console.log("run on is a set now , ");
    }
    let run_on = steps[key]?.runOn as unknown as Set<AGuardState>;

    try {
      if (!step?.loaderMsg) {
        step.loaderMsg = "Unknown Step Loader Message";
      }
      const checker_props = events?.next?.(step) as AGuardStepCheckerProps;
      if (run_on.has(checker_props.state)) {
        // checker_props.state = state;
        // console.log("state is :: ", checker_props.state);
        if (!step.checker) {
          throw new Error("Guarded: step has no checker function");
        }
        let err = undefined;
        await Promise.race([step?.checker?.(checker_props)])
          .then((e) => {
            if (general.isAsyncFunction(step?.checker)) {
              return;
            }
            err = e;
          })
          .catch((e) => {
            err = e;
          });
        // const err = await Promise.race(step?.checker?.(checker_props));
        // console.log("error is : ", err);
        if (err !== undefined && err !== null) {
          failed_error = err;
          throw new Error("checkGuardSteps: unknown guard error!");
        }
      }
    } catch (e: any) {
      if (step?.skipFailure) {
        failed_error = undefined;
        continue;
      }
      failed_step_key = key;
      failed_step = step;
      failed_error = !failed_error
        ? {
            error: e?.message,
          }
        : failed_error;
      break;
    }
  }
  if (!failed_step) {
    events.success?.();
  } else {
    // console.log("failed step is :: ", failed_step);
    events?.failure?.(
      {
        step: failed_step,
        error: failed_error as any,
        stepEquals: (step) => stepEquals(steps, step, failed_step),
      },
      failed_step_key
    );
  }
}
function stepEquals(steps: AGuardStepGroup, step1: AGuardStep, step2: AGuardStep): boolean {
  if (!steps || steps.length <= 0) {
    return false;
  }
  const s1_idx = steps.indexOf(step1);
  const s2_idx = steps.indexOf(step2);
  // console.log("comparing steps :: ", step1, step2, " :: and indecies :: ", s1_idx, s2_idx);
  return s1_idx >= 0 && s2_idx >= 0 && s1_idx === s2_idx;
}
